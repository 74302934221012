.bfImage {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	width: 100%;
	height: 100%;
	&__logo {
		background-image: url('/src/libs/material/assets/images/logotypes/logo.svg');
		.app--darkTheme &,
		.app--blueTheme & {
			background-image: url('/src/libs/material/assets/images/logotypes/logo_butterfly_white.png');
		}
	}
}
