@import '../../../../../../styles/themes.scss';
@import '../../../../../../libs/material/style/variables';

.cm-s-bf-dark.CodeMirror {
	background: theme-color('dark', 'mainColor');
	color: #e0e0e0;
	font-family: 'Inconsolata', monospace;
	font-size: fontSize('medium') !important;
}
.cm-s-bf-dark div.CodeMirror-selected {
	background: #2c534b;
	margin-top: -1px;
	margin-left: -1px;
	padding: 0 2px 2px 2px;
	&:first-child {
		border-bottom-left-radius: 3px;
		border-top-left-radius: 3px;
	}
	&:last-child {
		border-bottom-right-radius: 3px;
		border-top-right-radius: 3px;
	}
}
.CodeMirror-scrollbar-filler {
	background: #353535;
}
.CodeMirror-vscrollbar,
.CodeMirror-hscrollbar {
	&::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: #222;
	}
	&::-webkit-scrollbar-track {
		background: #353535;
		border-radius: 0;
	}
}
.cm-s-bf-dark .CodeMirror-line::selection,
.cm-s-bf-dark .CodeMirror-line > span::selection,
.cm-s-bf-dark .CodeMirror-line > span > span::selection {
	background: rgba(255, 255, 255, 0.5);
}
.cm-s-bf-dark .CodeMirror-line::-moz-selection,
.cm-s-bf-dark .CodeMirror-line > span::-moz-selection,
.cm-s-bf-dark .CodeMirror-line > span > span::-moz-selection {
	background: rgba(255, 255, 255, 0.5);
}
.cm-s-bf-dark .CodeMirror-gutters {
	background: theme-color('dark', 'mainColor');
	border-right: 0px;
}
.cm-s-bf-dark .CodeMirror-guttermarker {
	color: red;
}
.cm-s-bf-dark .CodeMirror-guttermarker-subtle {
	color: red;
}
.cm-s-bf-dark .CodeMirror-linenumber {
	color: #575757;
}
.cm-s-bf-dark .CodeMirror-cursor {
	border-left: 1px solid #b0b0b0;
}
.cm-s-bf-dark span.cm-comment {
	color: #8f5536;
}
.cm-s-bf-dark span.cm-atom {
	color: #aa759f;
}
.cm-s-bf-dark span.cm-number {
	color: #aa759f;
}
.cm-s-bf-dark span.cm-property,
.cm-s-bf-dark span.cm-attribute {
	color: #dbaa79;
}
.cm-s-bf-dark span.cm-keyword {
	color: #f7c86d;
}
.cm-s-bf-dark span.cm-string {
	color: #98c379;
}
.cm-s-bf-dark span.cm-variable {
	color: #90a959;
}
.cm-s-bf-dark span.cm-variable-2 {
	color: #6a9fb5;
}
.cm-s-bf-dark span.cm-def {
	color: #d28445;
}
.cm-s-bf-dark span.cm-bracket {
	color: #f7f7f7 !important;
}
.cm-s-bf-dark span.cm-tag {
	color: colors('orange');
}
.cm-s-bf-dark span.cm-link {
	color: #aa759f;
}
.cm-s-bf-dark span.cm-error {
}
.cm-s-bf-dark .CodeMirror-activeline-background {
	background: #202020;
}
.cm-s-bf-dark .CodeMirror-matchingbracket {
	text-decoration: underline;
	color: white !important;
}
.cm-s-bf-dark .cm-bfmode {
	background: #517fdc;
	border-radius: 3px;
	padding: 1px 2px;
	color: white;
	&.cm-render {
		background: red;
		color: white;
	}
}
.cm-s-bf-dark .cm-smart-selector {
	color: #fff;
	padding: 1px 5px 1px 5px;
	pointer-events: all;
	cursor: pointer;
	position: relative;
	font-size: 12px;
	-moz-user-select: none;
	-ms-user-select: none;
	-webkit-user-select: none;
	user-select: none;
	margin: 2px 0;
	border-radius: 3px;
	line-height: 22px;
	position: relative;
	&.type-text {
		background: #517fdc;
		border: 1px solid #3560b7;
		box-shadow: 0px 0px 0 1px #658bd6;
		&:hover {
			background-color: lighten(#3560b7, 5);
		}
	}
	&.type-render {
		background: yellow;
		&:hover {
			background-color: lighten(yellow, 10);
		}
	}
	.cm-smart-selector-name {
		color: rgba(255, 255, 255, 0.57);
		border-right: 1px solid #456dbd;
		padding-right: 3px;
		margin-right: 3px;
		font-size: 11px !important;
		vertical-align: 1px;
	}
}
.cm-s-bf-dark .CodeMirror-cursors {
	.CodeMirror-cursor {
		border-left: 2px solid #2781c3;
	}

	z-index: 3;
}
.CodeMirror-sizer {
	padding-bottom: 30px !important;
}
