$animations: (
	'fade': 'fade',
	'fadeRight': 'fadeRight',
	'zoomBack': 'zoomBack',
);

@mixin bfAnimation(
	$name: 'fade',
	$type: 'in',
	$duration: 0.2s,
	$easing: linear,
	$delay: 0s
) {
	$blockName: 'bfAnimation__';
	$typeSeparator: '--';
	/*animation: name duration timing-function delay iteration-count direction fill-mode play-state;*/
	animation: unquote($blockName + $name + $typeSeparator + $type) $duration
		$easing $delay 1 normal forwards;
}

@-webkit-keyframes bfAnimation__fade--in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-moz-keyframes bfAnimation__fade--in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-ms-keyframes bfAnimation__fade--in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-o-keyframes bfAnimation__fade--in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes bfAnimation__fade--in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes bfAnimation__fade--out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@-moz-keyframes bfAnimation__fade--out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@-ms-keyframes bfAnimation__fade--out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@-o-keyframes bfAnimation__fade--out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes bfAnimation__fade--out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@-webkit-keyframes bfAnimation__fadeRight--in {
	0% {
		opacity: 0;
		transform: translateX(-15px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@-moz-keyframes bfAnimation__fadeRight--in {
	0% {
		opacity: 0;
		transform: translateX(-15px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@-ms-keyframes bfAnimation__fadeRight--in {
	0% {
		opacity: 0;
		transform: translateX(-15px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@-o-keyframes bfAnimation__fadeRight--in {
	0% {
		opacity: 0;
		transform: translateX(-15px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes bfAnimation__fadeRight--in {
	0% {
		opacity: 0;
		transform: translateX(-15px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@-webkit-keyframes bfAnimation__fadeRight--out {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		transform: translateX(-15px);
	}
}

@-moz-keyframes bfAnimation__fadeRight--out {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		transform: translateX(-15px);
	}
}

@-ms-keyframes bfAnimation__fadeRight--out {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		transform: translateX(-15px);
	}
}

@-o-keyframes bfAnimation__fadeRight--out {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		transform: translateX(-15px);
	}
}

@keyframes bfAnimation__fadeRight--out {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		transform: translateX(-15px);
	}
}

@-webkit-keyframes bfAnimation__zoomBack--in {
	0% {
		opacity: 0;
		transform: scale(0.5);
	}
	50% {
		opacity: 1;
		transform: scale(1.1);
	}
	80% {
		opacity: 1;
		transform: scale(0.95);
	}
	100% {
		opacity: 1;
		transform: none;
	}
}

@-moz-keyframes bfAnimation__zoomBack--in {
	0% {
		opacity: 0;
		transform: scale(0.5);
	}
	50% {
		opacity: 1;
		transform: scale(1.1);
	}
	80% {
		opacity: 1;
		transform: scale(0.95);
	}
	100% {
		opacity: 1;
		transform: none;
	}
}

@-ms-keyframes bfAnimation__zoomBack--in {
	0% {
		opacity: 0;
		transform: scale(0.5);
	}
	50% {
		opacity: 1;
		transform: scale(1.1);
	}
	80% {
		opacity: 1;
		transform: scale(0.95);
	}
	100% {
		opacity: 1;
		transform: none;
	}
}

@-o-keyframes bfAnimation__zoomBack--in {
	0% {
		opacity: 0;
		transform: scale(0.5);
	}
	50% {
		opacity: 1;
		transform: scale(1.1);
	}
	80% {
		opacity: 1;
		transform: scale(0.95);
	}
	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes bfAnimation__zoomBack--in {
	0% {
		opacity: 0;
		transform: scale(0.5);
	}
	50% {
		opacity: 1;
		transform: scale(1.1);
	}
	80% {
		opacity: 1;
		transform: scale(0.95);
	}
	100% {
		opacity: 1;
		transform: none;
	}
}

@-webkit-keyframes bfAnimation__zoomBack--out {
	0% {
		opacity: 1;
		transform: none;
	}
	20% {
		opacity: 1;
		transform: scale(0.95);
	}
	50% {
		opacity: 1;
		transform: scale(1.1);
	}
	100% {
		opacity: 0;
		transform: scale(0.5);
	}
}

@-moz-keyframes bfAnimation__zoomBack--out {
	0% {
		opacity: 1;
		transform: none;
	}
	20% {
		opacity: 1;
		transform: scale(0.95);
	}
	50% {
		opacity: 1;
		transform: scale(1.1);
	}
	100% {
		opacity: 0;
		transform: scale(0.5);
	}
}

@-ms-keyframes bfAnimation__zoomBack--out {
	0% {
		opacity: 1;
		transform: none;
	}
	20% {
		opacity: 1;
		transform: scale(0.95);
	}
	50% {
		opacity: 1;
		transform: scale(1.1);
	}
	100% {
		opacity: 0;
		transform: scale(0.5);
	}
}

@-o-keyframes bfAnimation__zoomBack--out {
	0% {
		opacity: 1;
		transform: none;
	}
	20% {
		opacity: 1;
		transform: scale(0.95);
	}
	50% {
		opacity: 1;
		transform: scale(1.1);
	}
	100% {
		opacity: 0;
		transform: scale(0.5);
	}
}

@keyframes bfAnimation__zoomBack--out {
	0% {
		opacity: 1;
		transform: none;
	}
	20% {
		opacity: 1;
		transform: scale(0.95);
	}
	50% {
		opacity: 1;
		transform: scale(1.1);
	}
	100% {
		opacity: 0;
		transform: scale(0.5);
	}
}
