@import '../../../../../../libs/material/style/variables';
@import '../../../../../../styles/themes';
@import '../../../../../../libs/material/style/mixins.scss';

$fileBrowser: (
	'width': 30em,
	'itemHeight': 2.4rem,
	'itemPadding': 1.5rem,
	'levelIndent': 2rem,
	'hoverColor': #e7e7e7,
	'darkHoverColor': #3e3e3e,
	'caretWidth': 1.5rem,
	'states': (
		'NEW': '1',
		'MODIEFIED': '2',
		'DELETED': '3',
	),
);
.fileBrowser {
	height: 100%;
	width: 100%;
	.bf-icon {
		display: inline-block;
		height: map-get($fileBrowser, 'itemHeight');
		line-height: map-get($fileBrowser, 'itemHeight');
		cursor: pointer;
		transition: transform 0.1s ease;
		color: #eee;
		vertical-align: top;
		&:hover {
			color: #fff;
			transform: scale(1.085);
		}
	}
	&__header {
		font-size: fontSize('medium');
		display: flex;
		padding: 0 0 0 0;
		line-height: 5rem;
		border-bottom: 1px solid #424242;
		&Name {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			position: relative;
			padding: 0 10rem 0 1rem;
		}
		.app--darkTheme & {
			border-bottom: 1px solid theme-color('dark', 'borderColor');
		}
	}
	&__scrollView {
		position: absolute;
		display: block;
		top: 5.1rem;
		overflow-y: auto;
		left: 0;
		right: 0;
		bottom: 0;
	}
	&__buttons {
		font-size: 1.2rem;
		position: absolute;
		right: 10px;
		top: 0;

		i.bf-icon,
		a.bf-icon {
			vertical-align: middle;
			margin: 0 0.5rem;
		}
	}
	@include clearfix();
}
