.bfInlineEdit__input {
  display: inline-block;
  position: relative;
  cursor: pointer;
  word-break: break-all;
  letter-spacing: inherit;
  z-index: 1;
  outline: 1px solid rgba(0, 0, 0, 0);
  min-width: 1em;
  min-height: 1em;
  max-width: 100%;
}
.bfInlineEdit__input:hover:not(.bfInlineEdit__input--preventClick)::before {
  opacity: 0.7;
  z-index: -1;
}
.bfInlineEdit__input input {
  padding: 0;
  margin: 0;
  cursor: text;
  background-color: transparent;
  outline: none;
  border: none;
  white-space: nowrap;
  font-size: inherit;
  color: inherit;
  max-width: 100%;
  letter-spacing: 0.42;
  line-height: inherit;
  position: relative;
}
.bfInlineEdit__input input:hover {
  background: rgba(0, 0, 0, 0.1);
}
.bfInlineEdit__input::before {
  content: "";
  position: absolute;
  left: -5px;
  top: -5px;
  bottom: -5px;
  right: -5px;
  border-radius: 2px;
  background: white;
  z-index: 0;
  border: 1px solid #dfdfdf;
  opacity: 0;
  transition: opacity 0.2s ease;
}
.app--darkTheme .bfInlineEdit__input::before {
  background: rgba(0, 0, 0, 0.3);
}
.bfInlineEdit__input--active input {
  background: transparent;
  z-index: 1;
}
.bfInlineEdit__input--active::before {
  opacity: 1 !important;
}
.bfInlineEdit__input--inValid {
  position: relative;
}
.bfInlineEdit__input--inValid input {
  outline-color: colors("red") !important;
}