@import '../../../../../styles/themes';

.footer {
	display: flex;
	height: 4rem;

	&__section {
		flex: 1;
		max-width: 150px;
		padding: 0.5rem 0.4rem;

		&--center {
			max-width: none;
			padding: 0;
			text-align: center;
		}

		&--right {
			text-align: right;
		}
	}

	&__menu {
		display: inline-block;
		height: 100%;

		&Item {
			display: inline-block;
			height: calc(100% - 6px);
			width: 40px;
			cursor: pointer;
			border-radius: 1px;
			margin: 3px;
			padding: 4px 5px 5px 5px;
			text-align: center;
			text-transform: uppercase;
			font-size: 8px;
			color: #bbb;
			transition: color 0.1s ease;

			&:hover {
				color: #333;

				i {
					color: #555 !important;
				}
			}

			&--active {
				background: #f3f3f3;
				box-shadow: inset 0px 1px 1px 0px rgba(0, 0, 0, 0.3);
				color: #333;
				outline: none;

				i {
					color: #555 !important;
				}
			}

			&:focus,
			&:active {
				outline: none;
			}

			i {
				display: inline-block;
				color: #bbb;
				font-size: 14px;
				height: 16px;
				width: 20px;
				margin: 0 0 3px 0;
				transition: all 0.1s ease;
			}
		}
	}
}

:host-context(.app--darkTheme) {
	.footer {
		background: theme-color('dark', 'mainColor');
	}

	.footer__menuItem {
		color: #6f6f6f;

		&:hover {
			color: white;

			i {
				color: white !important;
			}
		}

		&--active {
			background: #292929;
			color: theme-color('dark', 'textColor');

			i {
				color: theme-color('dark', 'textColor') !important;
			}
		}

		i {
			color: #6f6f6f;
		}
	}
}
