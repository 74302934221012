@import '../../style/variables';
@import '../../style/mixins';
.bfTooltip {
	$tooltipColors: (
		'dark': #272734,
		'light': #fff,
		'red': colors('red'),
	);
	background: map-get($tooltipColors, 'dark');
	position: absolute;
	z-index: 100000;
	color: white;
	opacity: 0;
	top: 100px;
	left: 0;
	padding: 7px 8px;
	transition: opacity 0.25s ease;
	font-size: 10px;
	box-shadow: 0px 1px 4px 0px rgba(153, 153, 153, 0.5);
	max-width: 145px;
	text-align: center;
	line-height: 16px;
	word-break: break-word;
	//@include arrows(map-get($tooltipColors, "dark"));
	.app--darkTheme &,
	.app--blueTheme & {
		background: map-get($tooltipColors, 'light');
		color: #333;
		//@include setArrowColors(map-get($tooltipColors, "light"));
	}
	.app--blueTheme & {
		box-shadow:
			0 3px 6px rgba(0, 0, 0, 0.16),
			0 3px 6px rgba(0, 0, 0, 0.23);
	}
	&--leftAlign {
		text-align: left;
	}
	&--rightAlign {
		text-align: right;
	}
	&--wiggleAnimation {
		opacity: 1;
		transition: none;
		transform: none;
		&.bfTooltip--top {
			transform-origin: 50% 100%;
			animation: tooltip__wiggleTop 1.15s linear;
		}
	}
	&--whiteTheme {
		background: map-get($tooltipColors, 'light');
		color: #333;
		box-shadow: 0px 1px 4px 0px rgba(153, 153, 153, 0.5);
	}
	&--redTheme {
		background: map-get($tooltipColors, 'red');
		color: #fff;
		box-shadow: 0px 1px 4px 0px rgba(153, 153, 153, 0.5);
		//@include setArrowColors(map-get($tooltipColors, "red"));
	}
	&--top {
		margin-top: -12px;
		transform: translateY(10px);
		transform-origin: 50% 100%;
	}
	&--bottom {
		margin-top: 12px;
		transform: translateY(-10px);
		transform-origin: 50% 0;
	}
	&--left {
		margin-left: -12px;
		transform: translateY(-10px);
		transform-origin: 50% 0;
	}
	&--right {
		margin-right: 12px;
		transform: translateY(-10px);
		transform-origin: 50% 0;
	}
	&--show {
		opacity: 1;
		transform: none;
	}
}

@-webkit-keyframes tooltip__wiggleTop {
	0% {
		transform: scale(0.5);
		opacity: 0;
	}
	8% {
		opacity: 1;
		transform: scale(1.05);
	}
	12% {
		opacity: 1;
		transform: scale(1);
	}
	20% {
		opacity: 1;
		transform: scale(1);
	}
	30% {
		transform: scale(1) rotate3d(0, 0, 1, -10deg);
	}
	40% {
		transform: scale(1) rotate3d(0, 0, 1, 5deg);
	}
	50% {
		transform: scale(1) rotate3d(0, 0, 1, -5deg);
	}
	60% {
		transform: rotate3d(0, 0, 1, 0deg);
	}

	to {
		transform: rotate3d(0, 0, 1, 0deg);
	}
}

@-moz-keyframes tooltip__wiggleTop {
	0% {
		transform: scale(0.5);
		opacity: 0;
	}
	8% {
		opacity: 1;
		transform: scale(1.05);
	}
	12% {
		opacity: 1;
		transform: scale(1);
	}
	20% {
		opacity: 1;
		transform: scale(1);
	}
	30% {
		transform: scale(1) rotate3d(0, 0, 1, -10deg);
	}
	40% {
		transform: scale(1) rotate3d(0, 0, 1, 5deg);
	}
	50% {
		transform: scale(1) rotate3d(0, 0, 1, -5deg);
	}
	60% {
		transform: rotate3d(0, 0, 1, 0deg);
	}

	to {
		transform: rotate3d(0, 0, 1, 0deg);
	}
}

@-ms-keyframes tooltip__wiggleTop {
	0% {
		transform: scale(0.5);
		opacity: 0;
	}
	8% {
		opacity: 1;
		transform: scale(1.05);
	}
	12% {
		opacity: 1;
		transform: scale(1);
	}
	20% {
		opacity: 1;
		transform: scale(1);
	}
	30% {
		transform: scale(1) rotate3d(0, 0, 1, -10deg);
	}
	40% {
		transform: scale(1) rotate3d(0, 0, 1, 5deg);
	}
	50% {
		transform: scale(1) rotate3d(0, 0, 1, -5deg);
	}
	60% {
		transform: rotate3d(0, 0, 1, 0deg);
	}

	to {
		transform: rotate3d(0, 0, 1, 0deg);
	}
}

@-o-keyframes tooltip__wiggleTop {
	0% {
		transform: scale(0.5);
		opacity: 0;
	}
	8% {
		opacity: 1;
		transform: scale(1.05);
	}
	12% {
		opacity: 1;
		transform: scale(1);
	}
	20% {
		opacity: 1;
		transform: scale(1);
	}
	30% {
		transform: scale(1) rotate3d(0, 0, 1, -10deg);
	}
	40% {
		transform: scale(1) rotate3d(0, 0, 1, 5deg);
	}
	50% {
		transform: scale(1) rotate3d(0, 0, 1, -5deg);
	}
	60% {
		transform: rotate3d(0, 0, 1, 0deg);
	}

	to {
		transform: rotate3d(0, 0, 1, 0deg);
	}
}

@keyframes tooltip__wiggleTop {
	0% {
		transform: scale(0.5);
		opacity: 0;
	}
	8% {
		opacity: 1;
		transform: scale(1.05);
	}
	12% {
		opacity: 1;
		transform: scale(1);
	}
	20% {
		opacity: 1;
		transform: scale(1);
	}
	30% {
		transform: scale(1) rotate3d(0, 0, 1, -10deg);
	}
	40% {
		transform: scale(1) rotate3d(0, 0, 1, 5deg);
	}
	50% {
		transform: scale(1) rotate3d(0, 0, 1, -5deg);
	}
	60% {
		transform: rotate3d(0, 0, 1, 0deg);
	}

	to {
		transform: rotate3d(0, 0, 1, 0deg);
	}
}
