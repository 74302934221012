@import '../../../../styles/themes.scss';

.codeView {
	&__sidebar {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 30rem;
		border-right: 1px solid #eee;
	}

	&__editor {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		transition: left 0.15s ease;

		.codeView--sidebar & {
			left: 30rem;
		}

		.codeView__editorFileTabs {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 50px;
		}

		.codeView__editorCodeEditor {
			position: absolute;
			top: 50px;
			left: 0;
			bottom: 0;
			right: 0;
		}

		.codeView__editorImagePreview {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: theme-color('dark', 'mainColor');

			.codeView__imagePreviewInner {
				position: absolute;
				top: 10%;
				left: 10%;
				width: 80%;
				height: 80%;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: 50% 50%;
			}
		}

		.codeView__editorNoPreview {
			position: absolute;
			top: 0;
			left: 0;
			display: table;
			width: 100%;
			height: 100%;
			background: theme-color('dark', 'mainColor');
			text-align: center;

			.codeView__editorNoPreviewInner {
				display: table-cell;
				vertical-align: middle;
			}
		}
	}
}

:host-context(.app--darkTheme) {
	.codeView {
		&__sidebar {
			border-color: theme-color('dark', 'borderColor');
		}
	}
}
