@import 'variables';
html {
	font-family:
		-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	font-size: 62.5%;
	color: colors('offBlack');
}

body {
	font-size: fontSize('default');
}

h1 {
	font-size: fontSize('extraLarge');
}
h2 {
	font-size: fontSize('large');
}
h3 {
	font-size: fontSize('medium');
}

p,
a,
h4,
h5,
h6,
li,
td,
input,
textarea {
	font-size: fontSize('default');
}
.bf-icon {
	line-height: inherit;
}
