@-webkit-keyframes #{ANIMATION_BASE_NAME__fadeRight--in } {
	0% {
		opacity: 0;
		transform: translateX(-15px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
@-moz-keyframes #{ANIMATION_BASE_NAME__fadeRight--in } {
	0% {
		opacity: 0;
		transform: translateX(-15px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
@-ms-keyframes #{ANIMATION_BASE_NAME__fadeRight--in } {
	0% {
		opacity: 0;
		transform: translateX(-15px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
@-o-keyframes #{ANIMATION_BASE_NAME__fadeRight--in } {
	0% {
		opacity: 0;
		transform: translateX(-15px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
@keyframes #{ANIMATION_BASE_NAME__fadeRight--in } {
	0% {
		opacity: 0;
		transform: translateX(-15px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@-webkit-keyframes #{ANIMATION_BASE_NAME__fadeRight--out } {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		transform: translateX(-15px);
	}
}

@-moz-keyframes #{ANIMATION_BASE_NAME__fadeRight--out } {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		transform: translateX(-15px);
	}
}

@-ms-keyframes #{ANIMATION_BASE_NAME__fadeRight--out } {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		transform: translateX(-15px);
	}
}

@-o-keyframes #{ANIMATION_BASE_NAME__fadeRight--out } {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		transform: translateX(-15px);
	}
}

@keyframes #{ANIMATION_BASE_NAME__fadeRight--out } {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		transform: translateX(-15px);
	}
}
