.swatches {
	$size: 1.1rem;
	$spacing: 0.3rem;

	position: relative;
	display: block;

	&__category {
		&Name {
			display: block;
		}

		&Column {
			width: $size + $spacing;
			float: left;
		}
		//@include clearfix();
	}

	&__column {
		width: $size + $spacing;
		float: left;
	}

	&__colors {
		display: flex;
		width: 100%;
		//@include clearfix();
	}

	&__color {
		width: $size;
		height: $size;
		font-size: 0.9rem;
		box-shadow: 0 0px 1px rgba(0, 0, 0, 0.3);
		border-radius: 1px;
		margin: 0 $spacing $spacing 0;
		cursor: pointer;
		float: left;
		text-align: center;
		color: white;
		text-shadow: 0 0 1px #0000004d;
		padding-top: 1px;

		.checkmark-icon {
			pointer-events: none;
			opacity: 0;
			line-height: $size;
			transition: opacity 0.2s ease;
		}

		&--active {
			box-shadow: 0 0px 1px rgba(0, 0, 0, 0.4);

			.checkmark-icon {
				text-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
				opacity: 1;
			}
		}

		&--last {
			margin-right: 0;
		}
	}
}
