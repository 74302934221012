/**
 * An arrow mixin for SASS, based on @shojberg's "cssarrowplease.com"
 * @param {Position} $position
 * @param {size, color} $arrow
 * @param {size, color} $border (optional)
 */

@function opposite-position($position) {
	@if $position == top {
		@return bottom;
	} @else if $position == bottom {
		@return top;
	} @else if $position == left {
		@return right;
	} @else if $position == right {
		@return left;
	}
}

@mixin drawArrow(
	$position: top,
	$arrowColor: white,
	$arrowSize: 6px,
	$borderWidth: 0,
	$borderColor: white
) {
	$oppositePosition: opposite-position($position);

	// Base CSS
	// position: relative;
	// background: $arrowColor;

	// Selector generation
	$selectors: '&:after';
	@if $borderWidth > 0 {
		$selectors: '#{$selectors}, &:before';
	}

	// Arrow position
	#{$selectors} {
		#{$oppositePosition}: 100%;

		// Offset
		@if $position == top or $position == bottom {
			left: 50%;
		} @else {
			top: 50%;
		}

		border: solid transparent;
		content: ' ';
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	// The arrow itself
	&:after {
		border-color: rgba(white, 0); // transparent
		border-#{$oppositePosition}-color: $arrowColor;
		border-width: $arrowSize;

		@if $position == top or $position == bottom {
			margin-left: -$arrowSize;
		} @else {
			margin-top: -$arrowSize;
		}
	}

	// The border
	@if $borderWidth > 0 {
		&:before {
			border-color: rgba(white, 0); // transparent
			border-#{$oppositePosition}-color: $borderColor;
			border-width: $arrowSize + $borderWidth;

			@if $position == top or $position == bottom {
				margin-left: -($arrowSize + $borderWidth);
			} @else {
				margin-top: -($arrowSize + $borderWidth);
			}
		}
	}
}

//TODO: THIS CAN BE OPTIMIZED BY JUST ROTATING THE LEFT ARROW
@mixin arrows($color, $size: 6px, $borderWidth: 0, $borderColor: null) {
	&--topArrow {
		@include drawArrow(top, $color, $size, $borderWidth, $borderColor);
	}
	&--rightArrow {
		@include drawArrow(right, $color, $size, $borderWidth, $borderColor);
	}
	&--bottomArrow {
		@include drawArrow(bottom, $color, $size, $borderWidth, $borderColor);
	}
	&--leftArrow {
		@include drawArrow(left, $color, $size, $borderWidth, $borderColor);
	}
}

@mixin setArrowColors($color: white, $borderColor: null) {
	&--topArrow {
		@include setArrowColor(top, $color, $borderColor);
	}
	&--rightArrow {
		@include setArrowColor(right, $color, $borderColor);
	}
	&--bottomArrow {
		@include setArrowColor(bottom, $color, $borderColor);
	}
	&--leftArrow {
		@include setArrowColor(left, $color, $borderColor);
	}
}

@mixin setArrowColor($type: top, $color: white, $borderColor: null) {
	$oppositePosition: opposite-position($type);

	// The arrow itself
	&:after {
		border-#{$oppositePosition}-color: $color;
	}

	// The border
	@if $borderColor != null {
		&:before {
			border-#{$oppositePosition}-color: $borderColor;
		}
	}
}
