/*
DEFAULT BROWSER FONT SIZES
h1 is   32px   (2em)
h2 is   24px (1.5em)
h3 is 20.8px (1.3em)
h4 is   16px   (1em)
h5 is 12.8px (0.8em)
h6 is 11.2px (0.7em)
*/
.bfImage {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
}
.bfImage__logo {
  background-image: url("/src/libs/material/assets/images/logotypes/logo.svg");
}
.app--darkTheme .bfImage__logo, .app--blueTheme .bfImage__logo {
  background-image: url("/src/libs/material/assets/images/logotypes/logo_butterfly_white.png");
}

/*
DEFAULT BROWSER FONT SIZES
h1 is   32px   (2em)
h2 is   24px (1.5em)
h3 is 20.8px (1.3em)
h4 is   16px   (1em)
h5 is 12.8px (0.8em)
h6 is 11.2px (0.7em)
*/
/*
DEFAULT BROWSER FONT SIZES
h1 is   32px   (2em)
h2 is   24px (1.5em)
h3 is 20.8px (1.3em)
h4 is   16px   (1em)
h5 is 12.8px (0.8em)
h6 is 11.2px (0.7em)
*/
html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 62.5%;
  color: #363636;
}

body {
  font-size: 1.2rem;
}

h1 {
  font-size: 2.2rem;
}

h2 {
  font-size: 1.8rem;
}

h3 {
  font-size: 1.4rem;
}

p,
a,
h4,
h5,
h6,
li,
td,
input,
textarea {
  font-size: 1.2rem;
}

.bf-icon {
  line-height: inherit;
}

.bfInlineEdit__input {
  display: inline-block;
  position: relative;
  cursor: pointer;
  word-break: break-all;
  letter-spacing: inherit;
  z-index: 1;
  outline: 1px solid rgba(0, 0, 0, 0);
  min-width: 1em;
  min-height: 1em;
  max-width: 100%;
}
.bfInlineEdit__input:hover:not(.bfInlineEdit__input--preventClick)::before {
  opacity: 0.7;
  z-index: -1;
}
.bfInlineEdit__input input {
  padding: 0;
  margin: 0;
  cursor: text;
  background-color: transparent;
  outline: none;
  border: none;
  white-space: nowrap;
  font-size: inherit;
  color: inherit;
  max-width: 100%;
  letter-spacing: 0.42;
  line-height: inherit;
  position: relative;
}
.bfInlineEdit__input input:hover {
  background: rgba(0, 0, 0, 0.1);
}
.bfInlineEdit__input::before {
  content: "";
  position: absolute;
  left: -5px;
  top: -5px;
  bottom: -5px;
  right: -5px;
  border-radius: 2px;
  background: white;
  z-index: 0;
  border: 1px solid #dfdfdf;
  opacity: 0;
  transition: opacity 0.2s ease;
}
.app--darkTheme .bfInlineEdit__input::before {
  background: rgba(0, 0, 0, 0.3);
}
.bfInlineEdit__input--active input {
  background: transparent;
  z-index: 1;
}
.bfInlineEdit__input--active::before {
  opacity: 1 !important;
}
.bfInlineEdit__input--inValid {
  position: relative;
}
.bfInlineEdit__input--inValid input {
  outline-color: #ff594b !important;
}

/*
DEFAULT BROWSER FONT SIZES
h1 is   32px   (2em)
h2 is   24px (1.5em)
h3 is 20.8px (1.3em)
h4 is   16px   (1em)
h5 is 12.8px (0.8em)
h6 is 11.2px (0.7em)
*/
@-webkit-keyframes bfDatePicker__selectedDay {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
@-moz-keyframes bfDatePicker__selectedDay {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
@-ms-keyframes bfDatePicker__selectedDay {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
@-o-keyframes bfDatePicker__selectedDay {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes bfDatePicker__selectedDay {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes bfDatePicker__selectedDayBetween {
  0% {
    background: #fff;
  }
  100% {
    background: rgb(226.0383064516, 237.8326612903, 251.4616935484);
  }
}
@-moz-keyframes bfDatePicker__selectedDayBetween {
  0% {
    background: #fff;
  }
  100% {
    background: rgb(226.0383064516, 237.8326612903, 251.4616935484);
  }
}
@-ms-keyframes bfDatePicker__selectedDayBetween {
  0% {
    background: #fff;
  }
  100% {
    background: rgb(226.0383064516, 237.8326612903, 251.4616935484);
  }
}
@-o-keyframes bfDatePicker__selectedDayBetween {
  0% {
    background: #fff;
  }
  100% {
    background: rgb(226.0383064516, 237.8326612903, 251.4616935484);
  }
}
@keyframes bfDatePicker__selectedDayBetween {
  0% {
    background: #fff;
  }
  100% {
    background: rgb(226.0383064516, 237.8326612903, 251.4616935484);
  }
}
bfDatePicker {
  display: block;
}

.bfDatePicker {
  display: block;
  position: relative;
}
.bfDatePicker__calendar {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: auto;
  z-index: 99;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
}
.app--blueTheme .bfDatePicker__calendar {
  color: white;
  background: rgba(0, 0, 0, 0);
}
.app--greenTheme .bfDatePicker__calendar {
  color: white;
  background: rgba(0, 0, 0, 0);
}
.bfDatePicker__calendarContainer {
  display: inline-block;
  width: 100%;
  padding: 10px;
}
.bfDatePicker__calendarContainerMonthSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  padding: 0.75rem 0;
}
.bfDatePicker__calendarContainerMonthSection i {
  font-size: 1.7rem;
  width: 3rem;
  text-align: center;
  height: 2.3rem;
  line-height: 2.3rem;
  cursor: pointer;
}
.bfDatePicker__calendarContainerMonthSection i:first-child {
  margin-left: 0.5rem;
  text-align: left;
}
.bfDatePicker__calendarContainerMonthSection i:last-child {
  margin-right: 0.5rem;
  text-align: right;
}
.bfDatePicker__calendarContainerMonthSectionTitle {
  font-size: 1.1rem;
  font-weight: bold;
  display: inline-block;
  height: 2.3rem;
  line-height: 2.3rem;
}
.bfDatePicker__calendarContainerDayNames {
  width: 100%;
  margin-top: 10px;
  display: inline-block;
  border: 1px solid transparent;
}
.bfDatePicker__calendarContainerDayNames > span {
  font-size: 1.2rem;
  display: block;
  float: left;
  width: 14.2857142857%;
  text-align: center;
}
.bfDatePicker__calendarDaysContainer {
  width: 100%;
  margin-top: 5px;
  border: 1px solid transparent;
}
.bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  font-size: 1.2rem;
  width: 14.2857142857%;
  height: 32px;
  text-align: center;
  cursor: pointer;
  background: transparent;
  overflow: hidden;
}
.bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay:after {
  content: " ";
  position: absolute;
  background: transparent;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDayInner {
  font-size: 1.2rem;
  height: 32px;
  display: inline-block;
  width: 100%;
  line-height: 32px;
}
.bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay--selectedFirst {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay--selectedFirst .bfDatePicker__calendarDayInner {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  background: #1b75dd;
  color: #fff;
  animation: bfDatePicker__selectedDay 0.15s cubic-bezier(0.19, 1, 0.22, 1);
}
.app--blueTheme .bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay--selectedFirst .bfDatePicker__calendarDayInner {
  color: rgb(27, 117, 221);
  background: white;
}
.app--greenTheme .bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay--selectedFirst .bfDatePicker__calendarDayInner {
  color: #1dab12;
  background: white;
}
.bfDatePicker--selecting .bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay--selectedFirst .bfDatePicker__calendarDayInner {
  border: 2px solid #1b75dd;
  color: #fff;
  background: #1b75dd;
  animation: none;
}
.app--blueTheme .bfDatePicker--selecting .bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay--selectedFirst .bfDatePicker__calendarDayInner, .app--greenTheme .bfDatePicker--selecting .bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay--selectedFirst .bfDatePicker__calendarDayInner {
  border-color: white;
  background: transparent;
}
.bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay.bfDatePicker__calendarDay--selectedLast {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay.bfDatePicker__calendarDay--selectedLast .bfDatePicker__calendarDayInner {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  background: #1b75dd;
  color: #fff;
  animation: bfDatePicker__selectedDay 0.15s cubic-bezier(0.19, 1, 0.22, 1);
}
.bfDatePicker--selecting .bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay.bfDatePicker__calendarDay--selectedLast .bfDatePicker__calendarDayInner {
  border: 2px solid #1b75dd;
  color: #1b75dd;
  background: white;
  animation: none;
}
.app--blueTheme .bfDatePicker--selecting .bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay.bfDatePicker__calendarDay--selectedLast .bfDatePicker__calendarDayInner, .app--greenTheme .bfDatePicker--selecting .bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay.bfDatePicker__calendarDay--selectedLast .bfDatePicker__calendarDayInner {
  color: white;
  border-color: white;
  background: transparent;
}
.app--blueTheme .bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay.bfDatePicker__calendarDay--selectedLast .bfDatePicker__calendarDayInner {
  color: rgb(27, 117, 221);
  background: white;
}
.app--greenTheme .bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay.bfDatePicker__calendarDay--selectedLast .bfDatePicker__calendarDayInner {
  color: #1dab12;
  background: white;
  border-color: white;
}
.bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay.bfDatePicker__calendarDay--selectedBetween .bfDatePicker__calendarDayInner {
  border-radius: 0;
  background: #fff;
  background: rgb(226.0383064516, 237.8326612903, 251.4616935484);
}
.app--blueTheme .bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay.bfDatePicker__calendarDay--selectedBetween .bfDatePicker__calendarDayInner, .app--greenTheme .bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay.bfDatePicker__calendarDay--selectedBetween .bfDatePicker__calendarDayInner {
  color: #fff;
  background: rgba(255, 255, 255, 0.4);
}
.bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay:hover:not(.bfDatePicker__calendarDay--disabled):not(.bfDatePicker__calendarDay--today):not(.bfDatePicker__calendarDay--selectedFirst):not(.bfDatePicker__calendarDay--selectedLast):not(.bfDatePicker__calendarDay--selectedBetween) .bfDatePicker__calendarDayInner {
  background: #fff;
  border: 2px solid #1b75dd;
  border-radius: 0.3rem;
}
.app--blueTheme .bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay:hover:not(.bfDatePicker__calendarDay--disabled):not(.bfDatePicker__calendarDay--today):not(.bfDatePicker__calendarDay--selectedFirst):not(.bfDatePicker__calendarDay--selectedLast):not(.bfDatePicker__calendarDay--selectedBetween) .bfDatePicker__calendarDayInner, .app--greenTheme .bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay:hover:not(.bfDatePicker__calendarDay--disabled):not(.bfDatePicker__calendarDay--today):not(.bfDatePicker__calendarDay--selectedFirst):not(.bfDatePicker__calendarDay--selectedLast):not(.bfDatePicker__calendarDay--selectedBetween) .bfDatePicker__calendarDayInner {
  color: white;
  border-color: white;
  background: transparent;
}
.app--blueTheme .bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay:hover:not(.bfDatePicker__calendarDay--disabled):not(.bfDatePicker__calendarDay--today):not(.bfDatePicker__calendarDay--selectedFirst):not(.bfDatePicker__calendarDay--selectedLast):not(.bfDatePicker__calendarDay--selectedBetween), .app--greenTheme .bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay:hover:not(.bfDatePicker__calendarDay--disabled):not(.bfDatePicker__calendarDay--today):not(.bfDatePicker__calendarDay--selectedFirst):not(.bfDatePicker__calendarDay--selectedLast):not(.bfDatePicker__calendarDay--selectedBetween) {
  border-color: white;
}
.bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay.bfDatePicker__calendarDay--disabled {
  pointer-events: none;
  opacity: 0.5;
}
.bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay.bfDatePicker__calendarDay--today:not(.bfDatePicker__calendarDay--selectedFirst):not(.bfDatePicker__calendarDay--selectedLast):not(.bfDatePicker__calendarDay--selectedBetween) {
  font-weight: bold;
  border-radius: 0.3rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.app--blueTheme .bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay.bfDatePicker__calendarDay--today:not(.bfDatePicker__calendarDay--selectedFirst):not(.bfDatePicker__calendarDay--selectedLast):not(.bfDatePicker__calendarDay--selectedBetween), .app--greenTheme .bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDay.bfDatePicker__calendarDay--today:not(.bfDatePicker__calendarDay--selectedFirst):not(.bfDatePicker__calendarDay--selectedLast):not(.bfDatePicker__calendarDay--selectedBetween) {
  border: 1px solid rgba(255, 255, 255, 0.4);
}
.bfDatePicker__calendarDaysContainer .bfDatePicker__calendarDayInner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bfDatePicker__calendarTimeContainer {
  width: 100%;
}
.bfDatePicker__calendarTimeContainer .bfDatePicker__calendarTime {
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  font-size: 1.2rem;
  width: 25%;
  height: 32px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}
.bfDatePicker__calendarTimeContainer .bfDatePicker__calendarTime:hover {
  background: #eee;
  border: 1px solid #eee;
  border-radius: 4px;
}
.bfDatePicker__calendarYearsContainer {
  width: 100%;
  height: 240px;
}
.bfDatePicker__calendarYearsContainer .bfDatePicker__calendarYear {
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  font-size: 14px;
  width: 25%;
  height: 50px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}
.bfDatePicker__calendarYearsContainer .bfDatePicker__calendarYear:hover, .bfDatePicker__calendarYearsContainer .bfDatePicker__calendarYear.bfDatePicker__calendarYear--selected {
  background: #eee;
  border: 1px solid #eee;
  border-radius: 4px;
}

.datePickerButton__container {
  position: relative;
  width: 287px;
  display: inline-block;
}
.datePickerButton__container .bf-icon-calendar {
  color: #1b75dd;
}
.datePickerButton__container--pristine .bf-icon-calendar {
  color: #000;
}
.datePickerButton__container .bf-icon-caret-down {
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}
.datePickerButton__container--selecting .bf-icon-caret-down {
  transform: rotateZ(-180deg);
}
.datePickerButton__container--selecting {
  box-shadow: 0px -2px 20px 0px rgba(0, 0, 0, 0.1);
}
.datePickerButton__container--selecting .bfDatePicker {
  box-shadow: 0px 15px 30px 5px rgba(0, 0, 0, 0.1);
}
.datePickerButton__container--isSpan {
  width: 317px;
}
.datePickerButton__caret {
  font-size: 1.3rem;
}
.datePickerButton__picker {
  position: absolute;
  z-index: 1;
  width: 100%;
  margin-top: -1px;
  border: 1px solid #e5e5e5;
  border-radius: 0 0 4px 4px;
}
.datePickerButton__button {
  display: flex;
  cursor: pointer;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #e5e5e5;
  min-width: 80px;
  height: 4rem;
  width: 100%;
}
.datePickerButton__buttonText {
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding-left: 20px;
  white-space: nowrap;
  overflow: hidden;
}
.datePickerButton__placeholderDateText {
  font-weight: 600;
  margin-left: 10px;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 20px;
}
.datePickerButton__placeholderText {
  color: #000;
}
.datePickerButton__placeholderDateText {
  color: #1b75dd;
}
.datePickerButton__caret {
  height: 100%;
  width: 4rem;
  text-align: center;
  line-height: 4rem;
  border-left: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*
DEFAULT BROWSER FONT SIZES
h1 is   32px   (2em)
h2 is   24px (1.5em)
h3 is 20.8px (1.3em)
h4 is   16px   (1em)
h5 is 12.8px (0.8em)
h6 is 11.2px (0.7em)
*/
@-webkit-keyframes dialog {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-moz-keyframes dialog {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-ms-keyframes dialog {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-o-keyframes dialog {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes dialog {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.bfDialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  z-index: 100;
  overflow: auto;
  color: #363636;
}
.bfDialog__close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.bfDialog__content {
  margin: 100px auto;
  max-width: 500px;
  min-height: 200px;
  background: white;
  box-shadow: 0px 4px 10px 0px rgba(96, 96, 96, 0.5);
  border-radius: 4px;
  animation: dialog 0.5s ease;
  position: relative;
}
.bfDialog__content .bfDialog__contentInner {
  padding: 55px;
  word-wrap: break-word;
}
.bfDialog__content .bfDialog__contentInner h4 {
  font-weight: 700;
}
.app--blueTheme .bfDialog__content {
  color: #fff;
  background: #1b75dd;
}

/*
DEFAULT BROWSER FONT SIZES
h1 is   32px   (2em)
h2 is   24px (1.5em)
h3 is 20.8px (1.3em)
h4 is   16px   (1em)
h5 is 12.8px (0.8em)
h6 is 11.2px (0.7em)
*/
.bfPopover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1010;
  display: none;
  max-width: 276px;
  padding: 1px;
  text-align: left;
  white-space: normal;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}
.bfPopover__content {
  padding: 9px 14px;
}
.bfPopover__arrow, .bfPopover__arrow:after {
  border-width: 11px;
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.bfPopover--left .bfPopover__arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-left-color: #999;
  border-left-color: rgba(0, 0, 0, 0.25);
  border-right-width: 0;
}
.bfPopover--left .bfPopover__arrow:after {
  right: 1px;
  bottom: -10px;
  border-left-color: #fff;
  border-right-width: 0;
  content: " ";
}
.bfPopover--right .bfPopover__arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-right-color: #999;
  border-right-color: rgba(0, 0, 0, 0.25);
  border-left-width: 0;
}
.bfPopover--right .bfPopover__arrow:after {
  bottom: -10px;
  left: 1px;
  border-right-color: #fff;
  border-left-width: 0;
  content: " ";
}

/*
DEFAULT BROWSER FONT SIZES
h1 is   32px   (2em)
h2 is   24px (1.5em)
h3 is 20.8px (1.3em)
h4 is   16px   (1em)
h5 is 12.8px (0.8em)
h6 is 11.2px (0.7em)
*/
@-webkit-keyframes bfTagInput__tag {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-moz-keyframes bfTagInput__tag {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-ms-keyframes bfTagInput__tag {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-o-keyframes bfTagInput__tag {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes bfTagInput__tag {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes bfTagInput__tagErrorFlash {
  0% {
    border-color: #ff594b;
    background: rgb(255, 239.5066666667, 238.2);
  }
  40% {
    border-color: #ff594b;
    background: rgb(255, 239.5066666667, 238.2);
  }
  100% {
    border-color: #1b75dd;
    background: #ffffff;
  }
}
@-moz-keyframes bfTagInput__tagErrorFlash {
  0% {
    border-color: #ff594b;
    background: rgb(255, 239.5066666667, 238.2);
  }
  40% {
    border-color: #ff594b;
    background: rgb(255, 239.5066666667, 238.2);
  }
  100% {
    border-color: #1b75dd;
    background: #ffffff;
  }
}
@-ms-keyframes bfTagInput__tagErrorFlash {
  0% {
    border-color: #ff594b;
    background: rgb(255, 239.5066666667, 238.2);
  }
  40% {
    border-color: #ff594b;
    background: rgb(255, 239.5066666667, 238.2);
  }
  100% {
    border-color: #1b75dd;
    background: #ffffff;
  }
}
@-o-keyframes bfTagInput__tagErrorFlash {
  0% {
    border-color: #ff594b;
    background: rgb(255, 239.5066666667, 238.2);
  }
  40% {
    border-color: #ff594b;
    background: rgb(255, 239.5066666667, 238.2);
  }
  100% {
    border-color: #1b75dd;
    background: #ffffff;
  }
}
@keyframes bfTagInput__tagErrorFlash {
  0% {
    border-color: #ff594b;
    background: rgb(255, 239.5066666667, 238.2);
  }
  40% {
    border-color: #ff594b;
    background: rgb(255, 239.5066666667, 238.2);
  }
  100% {
    border-color: #1b75dd;
    background: #ffffff;
  }
}
bfTagInput .bfTagInput {
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  color: #777;
  outline: none;
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 100%;
  min-height: 4rem;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0.3rem 0.4rem 0.3rem;
}
bfTagInput .bfTagInput--disabled {
  opacity: 0.5;
}
bfTagInput .bfTagInput--flashError {
  animation: bfTagInput__tagErrorFlash 0.65s ease;
}
bfTagInput .bfTagInput--focus {
  border-color: #1b75dd;
}
bfTagInput .bfTagInput--focus .bfTagInput__tag {
  background: #1b75dd;
  color: white;
}
bfTagInput .bfTagInput--focus .bfTagInput__tagRemove {
  color: white;
}
bfTagInput .bfTagInput--focus .bfTagInput__tag--erasing {
  transform: scale(0.95);
  box-shadow: 0 0 0 2px white, 0 0 0 3px #1b75dd;
}
bfTagInput .bfTagInput__tag {
  background-color: #eee;
  display: inline-block;
  line-height: 2.8rem;
  height: 2.8rem;
  margin: 0.5rem 0.2rem 0rem 0.2rem;
  padding: 0 0.5rem;
  flex-grow: 0;
  transition: background 0.1s ease, transform 0.25s ease;
  animation: bfTagInput__tag 0.15s ease;
}
bfTagInput .bfTagInput__tagText {
  user-select: none;
}
bfTagInput .bfTagInput__tagRemove {
  color: gray;
  font-size: 1.5rem;
  cursor: pointer;
  vertical-align: -0.1rem;
}
bfTagInput .bfTagInput__input {
  border: none !important;
  background: rgba(0, 0, 0, 0) !important;
  min-height: 2.8rem;
  height: 2.8rem;
  vertical-align: top;
  flex-grow: 1;
  min-width: 15rem;
  margin: 0.5rem 0.2rem 0 0.2rem;
}

/*
DEFAULT BROWSER FONT SIZES
h1 is   32px   (2em)
h2 is   24px (1.5em)
h3 is 20.8px (1.3em)
h4 is   16px   (1em)
h5 is 12.8px (0.8em)
h6 is 11.2px (0.7em)
*/
/*
DEFAULT BROWSER FONT SIZES
h1 is   32px   (2em)
h2 is   24px (1.5em)
h3 is 20.8px (1.3em)
h4 is   16px   (1em)
h5 is 12.8px (0.8em)
h6 is 11.2px (0.7em)
*/
.bfTooltip {
  background: #272734;
  position: absolute;
  z-index: 100000;
  color: white;
  opacity: 0;
  top: 100px;
  left: 0;
  padding: 7px 8px;
  transition: opacity 0.25s ease;
  font-size: 10px;
  box-shadow: 0px 1px 4px 0px rgba(153, 153, 153, 0.5);
  max-width: 145px;
  text-align: center;
  line-height: 16px;
  word-break: break-word;
}
.app--darkTheme .bfTooltip, .app--blueTheme .bfTooltip {
  background: #fff;
  color: #333;
}
.app--blueTheme .bfTooltip {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.bfTooltip--leftAlign {
  text-align: left;
}
.bfTooltip--rightAlign {
  text-align: right;
}
.bfTooltip--wiggleAnimation {
  opacity: 1;
  transition: none;
  transform: none;
}
.bfTooltip--wiggleAnimation.bfTooltip--top {
  transform-origin: 50% 100%;
  animation: tooltip__wiggleTop 1.15s linear;
}
.bfTooltip--whiteTheme {
  background: #fff;
  color: #333;
  box-shadow: 0px 1px 4px 0px rgba(153, 153, 153, 0.5);
}
.bfTooltip--redTheme {
  background: #ff594b;
  color: #fff;
  box-shadow: 0px 1px 4px 0px rgba(153, 153, 153, 0.5);
}
.bfTooltip--top {
  margin-top: -12px;
  transform: translateY(10px);
  transform-origin: 50% 100%;
}
.bfTooltip--bottom {
  margin-top: 12px;
  transform: translateY(-10px);
  transform-origin: 50% 0;
}
.bfTooltip--left {
  margin-left: -12px;
  transform: translateY(-10px);
  transform-origin: 50% 0;
}
.bfTooltip--right {
  margin-right: 12px;
  transform: translateY(-10px);
  transform-origin: 50% 0;
}
.bfTooltip--show {
  opacity: 1;
  transform: none;
}

@-webkit-keyframes tooltip__wiggleTop {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  8% {
    opacity: 1;
    transform: scale(1.05);
  }
  12% {
    opacity: 1;
    transform: scale(1);
  }
  20% {
    opacity: 1;
    transform: scale(1);
  }
  30% {
    transform: scale(1) rotate3d(0, 0, 1, -10deg);
  }
  40% {
    transform: scale(1) rotate3d(0, 0, 1, 5deg);
  }
  50% {
    transform: scale(1) rotate3d(0, 0, 1, -5deg);
  }
  60% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
@-moz-keyframes tooltip__wiggleTop {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  8% {
    opacity: 1;
    transform: scale(1.05);
  }
  12% {
    opacity: 1;
    transform: scale(1);
  }
  20% {
    opacity: 1;
    transform: scale(1);
  }
  30% {
    transform: scale(1) rotate3d(0, 0, 1, -10deg);
  }
  40% {
    transform: scale(1) rotate3d(0, 0, 1, 5deg);
  }
  50% {
    transform: scale(1) rotate3d(0, 0, 1, -5deg);
  }
  60% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
@-ms-keyframes tooltip__wiggleTop {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  8% {
    opacity: 1;
    transform: scale(1.05);
  }
  12% {
    opacity: 1;
    transform: scale(1);
  }
  20% {
    opacity: 1;
    transform: scale(1);
  }
  30% {
    transform: scale(1) rotate3d(0, 0, 1, -10deg);
  }
  40% {
    transform: scale(1) rotate3d(0, 0, 1, 5deg);
  }
  50% {
    transform: scale(1) rotate3d(0, 0, 1, -5deg);
  }
  60% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
@-o-keyframes tooltip__wiggleTop {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  8% {
    opacity: 1;
    transform: scale(1.05);
  }
  12% {
    opacity: 1;
    transform: scale(1);
  }
  20% {
    opacity: 1;
    transform: scale(1);
  }
  30% {
    transform: scale(1) rotate3d(0, 0, 1, -10deg);
  }
  40% {
    transform: scale(1) rotate3d(0, 0, 1, 5deg);
  }
  50% {
    transform: scale(1) rotate3d(0, 0, 1, -5deg);
  }
  60% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
@keyframes tooltip__wiggleTop {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  8% {
    opacity: 1;
    transform: scale(1.05);
  }
  12% {
    opacity: 1;
    transform: scale(1);
  }
  20% {
    opacity: 1;
    transform: scale(1);
  }
  30% {
    transform: scale(1) rotate3d(0, 0, 1, -10deg);
  }
  40% {
    transform: scale(1) rotate3d(0, 0, 1, 5deg);
  }
  50% {
    transform: scale(1) rotate3d(0, 0, 1, -5deg);
  }
  60% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}