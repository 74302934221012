@import '../libs/material/style/variables';

html {
	font-size: 62.5%;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

body {
	font-family:
		-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	color: colors('offBlack');

	&.app--darkTheme {
		color: colors('offWhite');
	}
}

p {
	line-height: 2rem;
}

a {
	color: colors('blue');
	text-decoration: underline;
	cursor: pointer;

	&:hover {
		color: darken(colors('blue'), 5);
	}
}

textarea  {
	font-family:
		-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	resize: none;
	background: #ffffff;
	border: 1px solid #dfdfdf;
	box-shadow: inset 0px 1px 1px 0px rgba(0, 0, 0, 0.5);

	&:focus {
		outline: none;
	}
}

input[type='text'] {
	padding: 0.65rem 0.8rem;
	border: 1px solid #cccccc;
	background: white;

	&:focus {
		border: 1px solid; //, colors('blue');
		outline: none;
	}

	.app--darkTheme & {
		background: #1f1f1f;
		border-color: #363636;
		color: white;
	}
}

.clearfix:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: ' ';
	clear: both;
	height: 0;
}

.clearfix {
	display: inline-block;
}

/* start commented backslash hack \*/
* html .clearfix {
	height: 1%;
}

.clearfix {
	display: block;
}

.seperator {
	border-bottom: 1px solid #979797;
	text-align: center;
	height: 5px;
	margin: 25px 0;

	&__text {
		font-size: fontSize('medium');
		display: inline-block;
		margin-top: -5px;
		padding: 0.1rem 2.2rem;
		background: var(--ui-color-background);
		color: #4a4a4a;
	}
}

hr {
	border-style: solid;
	border-bottom: 0;
	border-color: #eee;
}

table {
	color: inherit;
}

.ui-flag.tiny {
	width: 18px;
	height: 14px;
}
