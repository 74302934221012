@import '../../style/variables';
@-webkit-keyframes bfDatePicker__selectedDay {
	0% {
		transform: scale(0.8);
	}
	100% {
		transform: scale(1);
	}
}
@-moz-keyframes bfDatePicker__selectedDay {
	0% {
		transform: scale(0.8);
	}
	100% {
		transform: scale(1);
	}
}
@-ms-keyframes bfDatePicker__selectedDay {
	0% {
		transform: scale(0.8);
	}
	100% {
		transform: scale(1);
	}
}
@-o-keyframes bfDatePicker__selectedDay {
	0% {
		transform: scale(0.8);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes bfDatePicker__selectedDay {
	0% {
		transform: scale(0.8);
	}
	100% {
		transform: scale(1);
	}
}

@-webkit-keyframes bfDatePicker__selectedDayBetween {
	0% {
		background: #fff;
	}
	100% {
		background: lighten(colors('blue'), 45);
	}
}

@-moz-keyframes bfDatePicker__selectedDayBetween {
	0% {
		background: #fff;
	}
	100% {
		background: lighten(colors('blue'), 45);
	}
}

@-ms-keyframes bfDatePicker__selectedDayBetween {
	0% {
		background: #fff;
	}
	100% {
		background: lighten(colors('blue'), 45);
	}
}

@-o-keyframes bfDatePicker__selectedDayBetween {
	0% {
		background: #fff;
	}
	100% {
		background: lighten(colors('blue'), 45);
	}
}

@keyframes bfDatePicker__selectedDayBetween {
	0% {
		background: #fff;
	}
	100% {
		background: lighten(colors('blue'), 45);
	}
}

bfDatePicker {
	display: block;
}
.bfDatePicker {
	display: block;
	position: relative;

	$bfCaledarRadius: 0.3rem;
	$bfCalendarBlueColor: rgb(27, 117, 221);
	$bfCalendarGreenColor: #1dab12;
	&__calendar {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		width: auto;
		z-index: 99;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		background: white;
		.app--blueTheme & {
			color: white;
			background: rgba(0, 0, 0, 0);
		}
		.app--greenTheme & {
			color: white;
			background: rgba(0, 0, 0, 0);
		}
		&Container {
			display: inline-block;
			width: 100%;
			padding: 10px;
			&MonthSection {
				width: 100%;
				display: flex;
				justify-content: space-between;
				text-transform: uppercase;
				padding: 0.75rem 0;

				i {
					font-size: 1.7rem;
					width: 3rem;
					text-align: center;
					height: 2.3rem;
					line-height: 2.3rem;
					cursor: pointer;
					&:first-child {
						margin-left: 0.5rem;
						text-align: left;
					}
					&:last-child {
						margin-right: 0.5rem;
						text-align: right;
					}
				}
				&Title {
					font-size: 1.1rem;
					font-weight: bold;
					display: inline-block;
					height: 2.3rem;
					line-height: 2.3rem;
				}
			}
			&DayNames {
				width: 100%;
				margin-top: 10px;
				display: inline-block;
				border: 1px solid transparent;
				& > span {
					font-size: 1.2rem;
					display: block;
					float: left;
					width: calc(100% / 7);
					text-align: center;
				}
			}
		}
		&DaysContainer {
			width: 100%;
			margin-top: 5px;
			border: 1px solid transparent;
			.bfDatePicker__calendarDay {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				float: left;
				font-size: 1.2rem;
				width: calc(100% / 7);
				height: 32px;
				text-align: center;
				cursor: pointer;
				background: transparent;
				overflow: hidden;
				&:after {
					content: ' ';
					position: absolute;
					background: transparent;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
				&Inner {
					font-size: 1.2rem;
					height: 32px;
					display: inline-block;
					width: 100%;
					line-height: 32px;
				}
				&--selectedFirst {
					border-top-left-radius: $bfCaledarRadius;
					border-bottom-left-radius: $bfCaledarRadius;
					.bfDatePicker__calendarDayInner {
						border-top-left-radius: $bfCaledarRadius;
						border-bottom-left-radius: $bfCaledarRadius;
						background: colors('blue');
						color: colors('white');
						animation: bfDatePicker__selectedDay 0.15s
							cubic-bezier(0.19, 1, 0.22, 1);
						.app--blueTheme & {
							color: $bfCalendarBlueColor;
							background: white;
						}
						.app--greenTheme & {
							color: $bfCalendarGreenColor;
							background: white;
						}
						.bfDatePicker--selecting & {
							border: 2px solid colors('blue');
							color: colors('white');
							background: colors('blue');
							animation: none;
							.app--blueTheme &,
							.app--greenTheme & {
								border-color: white;
								background: transparent;
							}
						}
					}
				}
				&.bfDatePicker__calendarDay--selectedLast {
					border-top-right-radius: $bfCaledarRadius;
					border-bottom-right-radius: $bfCaledarRadius;
					.bfDatePicker__calendarDayInner {
						border-top-right-radius: $bfCaledarRadius;
						border-bottom-right-radius: $bfCaledarRadius;
						background: colors('blue');
						color: colors('white');
						animation: bfDatePicker__selectedDay 0.15s
							cubic-bezier(0.19, 1, 0.22, 1);
						.bfDatePicker--selecting & {
							border: 2px solid colors('blue');
							color: colors('blue');
							background: white;
							animation: none;
							.app--blueTheme &,
							.app--greenTheme & {
								color: white;
								border-color: white;
								background: transparent;
							}
						}
						.app--blueTheme & {
							color: $bfCalendarBlueColor;
							background: white;
						}
						.app--greenTheme & {
							color: $bfCalendarGreenColor;
							background: white;
							border-color: white;
						}
					}
				}
				&.bfDatePicker__calendarDay--selectedBetween {
					.bfDatePicker__calendarDayInner {
						border-radius: 0;
						background: #fff;
						background: lighten(colors('blue'), 45);
						.app--blueTheme &,
						.app--greenTheme & {
							color: #fff;
							background: rgba(255, 255, 255, 0.4);
						}
					}
				}
				&:hover:not(.bfDatePicker__calendarDay--disabled):not(
						.bfDatePicker__calendarDay--today
					):not(.bfDatePicker__calendarDay--selectedFirst):not(
						.bfDatePicker__calendarDay--selectedLast
					):not(.bfDatePicker__calendarDay--selectedBetween) {
					.bfDatePicker__calendarDayInner {
						background: colors('white');
						border: 2px solid colors('blue');
						border-radius: $bfCaledarRadius;
						.app--blueTheme &,
						.app--greenTheme & {
							color: white;
							border-color: white;
							background: transparent;
						}
					}
					.app--blueTheme &,
					.app--greenTheme & {
						border-color: white;
					}
				}
				&.bfDatePicker__calendarDay--disabled {
					pointer-events: none;
					opacity: 0.5;
				}
				&.bfDatePicker__calendarDay--today:not(
						.bfDatePicker__calendarDay--selectedFirst
					):not(.bfDatePicker__calendarDay--selectedLast):not(
						.bfDatePicker__calendarDay--selectedBetween
					) {
					font-weight: bold;
					border-radius: $bfCaledarRadius;
					border: 1px solid rgba(0, 0, 0, 0.2);
					.app--blueTheme &,
					.app--greenTheme & {
						border: 1px solid rgba(255, 255, 255, 0.4);
					}
				}
			}
			.bfDatePicker__calendarDayInner {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
		&TimeContainer {
			width: 100%;
			.bfDatePicker__calendarTime {
				display: flex;
				justify-content: center;
				align-items: center;
				float: left;
				font-size: 1.2rem;
				width: calc(100% / 4);
				height: 32px;
				text-align: center;
				border-radius: 50%;
				cursor: pointer;
				&:hover {
					background: #eee;
					border: 1px solid #eee;
					border-radius: 4px;
				}
			}
		}
		&YearsContainer {
			width: 100%;
			height: 240px;
			.bfDatePicker__calendarYear {
				display: flex;
				justify-content: center;
				align-items: center;
				float: left;
				font-size: 14px;
				width: calc(100% / 4);
				height: 50px;
				text-align: center;
				border-radius: 50%;
				cursor: pointer;
				&:hover,
				&.bfDatePicker__calendarYear--selected {
					background: #eee;
					border: 1px solid #eee;
					border-radius: 4px;
				}
			}
		}
	}
}
