bfHelpIcon {
	display: inline-block;
}
.bfHelpIcon {
	font-size: inherit;
	opacity: 0.5;
	&:hover {
		opacity: 1;
	}
}
