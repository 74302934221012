@import '../../../../../../../styles/themes.scss';
@import '../../../../../../../libs/material/style/variables';

.textPopOver {
	background: theme-color('dark', 'secondaryColor');
	border: 1px solid #363636;
	box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.8);
	border-radius: 1px;
	z-index: 100;
	&__content {
		padding: 6px 6px 25px 6px;
	}
	&__input {
		width: 250px;
		height: 150px;
		background: #212121;
		color: #fff;
		resize: none;
		font-size: 11px;
		line-height: 17px;
		padding: 4px 6px;
		border: none;
		box-shadow: none;
		border-radius: 2px;
		&::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background: #353535;
		}
		&::-webkit-scrollbar-track {
			background: #222;
			border-radius: 0;
		}
	}
	&__helptext {
		position: absolute;
		bottom: 10px;
		right: 10px;
		font-size: 10px;
		color: rgba(255, 255, 255, 0.3);
	}
	&__clear {
		position: absolute;
		bottom: 10px;
		left: 10px;
		font-size: 10px;
		cursor: pointer;
	}
	&__nameInput {
		color: rgba(255, 255, 255, 0.5);
		margin: 5px;
		.bfInlineEdit__input {
			height: 1.6rem;
			vertical-align: bottom;
			&--active {
				border-color: #444;
			}
			&:hover {
				border-color: #444;
			}
		}
	}
}
