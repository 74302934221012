@import '../../fileBrowser/fileBrowserFile/fileBrowserFile.component.scss';

$fileBrowser: (
	'width': 30em,
	'itemHeight': 2.4rem,
	'itemPadding': 1.5rem,
	'levelIndent': 2rem,
	'hoverColor': #e7e7e7,
	'darkHoverColor': #3e3e3e,
	'caretWidth': 1.5rem,
	'states': (
		'NEW': '1',
		'MODIEFIED': '2',
		'DELETED': '3',
	),
);
@mixin fileBrowserItem($type: 'folder') {
	.fileBrowser__#{$type} {
		width: 100%;
		height: auto;
		position: relative;

		&HeaderContainer {
			display: block;
			position: relative;
		}

		&Header {
			height: map-get($fileBrowser, itemHeight);
			line-height: map-get($fileBrowser, itemHeight);
			padding: 0 map-get($fileBrowser, itemPadding);
			position: relative;

			&Buttons {
				opacity: 0;
				transition: opacity 0.3s ease;
				pointer-events: none;
				float: right;
				user-select: none;

				.bf-icon {
					cursor: pointer;
				}

				.fileBrowser__#{$type}Header:hover & {
					pointer-events: auto;
					opacity: 1;
				}
			}

			&--indent {
				&0 {
					background: map-get($fileBrowser, hoverColor);
					.app--darkTheme & {
						background: map-get($fileBrowser, darkHoverColor);
					}
				}

				@for $i from 1 through 12 {
					&#{$i} {
						padding-left: map-get($fileBrowser, itemPadding) +
							($i - 1) *
							map-get($fileBrowser, levelIndent);
					}
				}
			}

			&:hover {
				background-color: map-get($fileBrowser, hoverColor);
				.app--darkTheme & {
					background: map-get($fileBrowser, darkHoverColor);
				}
			}
		}

		&Collapse {
			display: block;
			position: absolute;
			font-size: 0.8em;
			cursor: pointer;
			width: map-get($fileBrowser, caretWidth);
			left: - map-get($fileBrowser, caretWidth);
			top: 0;
			text-align: center;

			.bf-icon {
				transition: transform 0.2s ease;
			}
		}

		&--collapsed {
			.fileBrowser__#{$type}Collapse .bf-icon {
				transform: rotate(-90deg);
			}
		}
	}
}

// Use the mixin
@include fileBrowserItem('folder');

// Uncomment and adjust if you need the file item styles
@include fileBrowserItem('file');
