.datePickerButton {
	&__container {
		position: relative;
		width: 287px;
		display: inline-block;
		.bf-icon-calendar {
			color: #1b75dd;
		}
		&--pristine .bf-icon-calendar {
			color: #000;
		}
		.bf-icon-caret-down {
			transition:
				transform 0.25s ease,
				-webkit-transform 0.25s ease;
		}
		&--selecting .bf-icon-caret-down {
			transform: rotateZ(-180deg);
		}
		&--selecting {
			box-shadow: 0px -2px 20px 0px rgba(0, 0, 0, 0.1);
		}
		&--selecting .bfDatePicker {
			box-shadow: 0px 15px 30px 5px rgba(0, 0, 0, 0.1);
		}
		&--isSpan {
			width: 317px;
		}
	}
	&__caret {
		font-size: 1.3rem;
	}
	&__picker {
		position: absolute;
		z-index: 1;
		width: 100%;
		margin-top: -1px;
		border: 1px solid #e5e5e5;
		border-radius: 0 0 4px 4px;
	}
	&__button {
		display: flex;
		cursor: pointer;
		box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
		border: 1px solid #e5e5e5;
		min-width: 80px;
		height: 4rem;
		width: 100%;
	}
	&__buttonText {
		display: flex;
		flex-grow: 1;
		align-items: center;
		padding-left: 20px;
		white-space: nowrap;
		overflow: hidden;
	}
	&__placeholderDateText {
		font-weight: 600;
		margin-left: 10px;
		font-size: 12px;
		text-overflow: ellipsis;
		overflow: hidden;
		padding-right: 20px;
	}
	&__placeholderText {
		color: #000;
	}
	&__placeholderDateText {
		color: #1b75dd;
	}
	&__caret {
		height: 100%;
		width: 4rem;
		text-align: center;
		line-height: 4rem;
		border-left: 1px solid #ddd;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
