.CodeMirror-dialog {
	position: absolute;
	left: 0;
	right: 0;
	background: inherit;
	z-index: 15;
	padding: 0.1em 0.8em;
	overflow: hidden;
	color: inherit;
	font-size: 12px !important;
}
.CodeMirror-dialog-top {
	border-bottom: 1px solid #565656;
	top: 0;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.CodeMirror-dialog-bottom {
	border-top: 1px solid #eee;
	bottom: 0;
}
.CodeMirror-dialog input[type='text'] {
	outline: none;
	background: transparent;
	width: 20em;
	color: inherit;
	font-family: monospace;
	font-size: 12px !important;
	width: 10em;
	height: 11px;
	padding: 3px 4px;
	margin-bottom: 5px;
	border: 1px solid #555;
}
.CodeMirror-dialog button {
	font-size: 12px !important;
}
