.bfInlineEdit__input {
	$grayBorder: 1px solid #dfdfdf;
	$shadow: 0 1px 4px rgba(78, 72, 72, 0.12);
	$padding: 5px;

	display: inline-block;
	position: relative;
	cursor: pointer;
	word-break: break-all;
	letter-spacing: inherit;
	z-index: 1;
	outline: 1px solid rgba(0, 0, 0, 0);
	min-width: 1em;
	min-height: 1em;
	max-width: 100%;

	&:hover:not(&--preventClick) {
		&::before {
			opacity: 0.7;
			z-index: -1;
		}
	}

	input {
		padding: 0;
		margin: 0;
		cursor: text;
		background-color: transparent;
		outline: none;
		border: none;
		white-space: nowrap;
		font-size: inherit;
		color: inherit;
		max-width: 100%;
		letter-spacing: 0.42;
		line-height: inherit;
		position: relative;

		&:hover {
			background: rgba(0, 0, 0, 0.1);
		}
	}

	&::before {
		content: '';
		position: absolute;
		left: -$padding;
		top: -$padding;
		bottom: -$padding;
		right: -$padding;
		border-radius: 2px;
		background: white;
		z-index: 0;
		border: $grayBorder;
		opacity: 0;
		transition: opacity 0.2s ease;

		.app--darkTheme & {
			background: rgba(0, 0, 0, 0.3);
		}
	}

	&--active {
		input {
			background: transparent;
			z-index: 1;
		}

		&::before {
			opacity: 1 !important;
		}
	}

	&--preventClick {
		// Empty rule, kept for consistency
	}

	&--inValid {
		position: relative;

		input {
			outline-color: colors('red') !important;
		}
	}
}
