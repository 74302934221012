@import '../../../../../../styles/themes.scss';

.codeEditor {
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	.app--darkTheme & {
		background: theme-color('dark', 'mainColor');
	}
	&__wrapper {
		position: absolute;
		top: 20px;
		left: 0;
		right: 0;
		bottom: 0;
		.CodeMirror {
			*,
			*:before,
			*:after {
				box-sizing: content-box !important;
			}

			height: 100%;
			font-size: 12px;
			cursor: text;
			border: none;
			.CodeMirror-gutters {
				min-height: 100%;
			}
			.CodeMirror-vscrollbar {
				cursor: default;
			}
		}
		.CodeMirror-hints {
			z-index: 999999999;
		}
	}
	&__translateButton {
		opacity: 0;
		pointer-events: none;
		background: #272727;
		width: 18px;
		height: 18px;
		box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
		border-radius: 100px;
		padding: 2px 0 0 1px;
		text-align: center;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 10;
		cursor: pointer;
		transition:
			opacity 0.15s ease,
			transform 0.1s ease;
		transform: scale(0.5);
		color: #888;
		&:hover {
			color: white;
		}
		&--visible {
			opacity: 1;
			pointer-events: all;
			transform: none;
			transition-delay: 0.5s;
		}
		.bf-icon {
			font-size: 9px;
			text-shadow: 0 1px 4px rgba(0, 0, 0, 0.62);
		}
	}
}
