/**
 * An arrow mixin for SASS, based on @shojberg's "cssarrowplease.com"
 * @param {Position} $position - top, right, bottom, or left
 * @param {List} $arrow - list containing size and color of the arrow
 * @param {List} $border - optional list containing size and color of the border (default: 0 white)
 */
@mixin arrow($position, $arrow, $border: 0 white) {
	// Resolve arguments
	$arrowSize: nth($arrow, 1);
	$arrowColor: nth($arrow, 2);
	$borderWidth: nth($border, 1);
	$borderColor: nth($border, 2);

	$oppositePosition: opposite-position($position);

	// Base CSS
	//position: relative;
	//background: $arrowColor;

	// Selector generation
	$selectors: '&:after';
	@if $borderWidth > 0 {
		$selectors: $selectors + ', &:before';
	}

	// Arrow position
	#{$selectors} {
		#{$oppositePosition}: 100%;

		// Offset
		@if $position == top or $position == bottom {
			left: 50%;
		} @else {
			top: 50%;
		}

		border: solid transparent;
		content: ' ';
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	// The arrow itself
	&:after {
		border-color: rgba(255, 255, 255, 0); // transparent
		border-#{$oppositePosition}-color: $arrowColor;
		border-width: $arrowSize;

		@if $position == top or $position == bottom {
			margin-left: -$arrowSize;
		} @else {
			margin-top: -$arrowSize;
		}
	}

	// The border
	@if $borderWidth > 0 {
		&:before {
			border-color: rgba(255, 255, 255, 0); // transparent
			border-#{$oppositePosition}-color: $borderColor;
			border-width: $arrowSize + $borderWidth;

			@if $position == top or $position == bottom {
				margin-left: -($arrowSize + $borderWidth);
			} @else {
				margin-top: -($arrowSize + $borderWidth);
			}
		}
	}
}

// Helper function to get the opposite position
@function opposite-position($position) {
	@if $position == top {
		@return bottom;
	} @else if $position == bottom {
		@return top;
	} @else if $position == left {
		@return right;
	} @else if $position == right {
		@return left;
	}
	@return left;
}
