@import '../../style/variables';
.bfPopover {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1010;
	display: none;
	max-width: 276px;
	padding: 1px;
	text-align: left;
	white-space: normal;
	background-color: #fff;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	background-clip: padding-box;
	&__content {
		padding: 9px 14px;
	}
	&__arrow,
	&__arrow:after {
		border-width: 11px;
		position: absolute;
		display: block;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
	}
	&--left {
		.bfPopover__arrow {
			top: 50%;
			right: -11px;
			margin-top: -11px;
			border-left-color: #999;
			border-left-color: rgba(0, 0, 0, 0.25);
			border-right-width: 0;
			&:after {
				right: 1px;
				bottom: -10px;
				border-left-color: #fff;
				border-right-width: 0;
				content: ' ';
			}
		}
	}
	&--right {
		.bfPopover__arrow {
			top: 50%;
			left: -11px;
			margin-top: -11px;
			border-right-color: #999;
			border-right-color: rgba(0, 0, 0, 0.25);
			border-left-width: 0;
			&:after {
				bottom: -10px;
				left: 1px;
				border-right-color: #fff;
				border-left-width: 0;
				content: ' ';
			}
		}
	}
}
