@import 'variables';

.errorPage {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: colors('offWhite');

	&__panel {
		background: #ffffff;
		border: 1px solid rgba(151, 151, 151, 0.3);
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
		border-radius: 2px;
		min-width: 250px;
		min-height: 150px;
		max-width: 450px;
		margin: 100px auto 0 auto;
		padding: 40px 25px;
		text-align: center;
	}
}
